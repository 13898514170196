import React from "react"
import { Link, graphql } from "gatsby"
import Moment from 'react-moment';

import Layout from "../components/layout"
import Bio from "../components/bio"
//import SEO from "../components/seo"

import { rhythm } from "../utils/typography"

import LoginButton from "../components/auth/loginButton"
import LogoutButton from "../components/auth/logoutButton"


//import { useAuth0 } from "@auth0/auth0-react";


class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMysqlPost.edges


    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Bio />
        <h3 style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }} >
        <Link style={{ boxShadow: `none` }} to={"/archive"} state={{ showDay: false }}>
          Browse the Archive
        </Link> 
        </h3>

        <h3 style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}>
          <Link style={{ boxShadow: `none` }} to="/search">Search Keywords</Link>
        </h3>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMysqlPost(sort: {fields: created, order: DESC}, limit: 4) {
      edges {
        node {
          id
          mysqlId
          content
          created
          created_utc
          author
          community_id
          media {
            filename
            contentType
            height
            width
          }
        }
      }
    }
  }
`
